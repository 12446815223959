<template>
	<form
		class="form-create-rate main-wrapper mt-4"
		@submit.prevent="handleSubmit"
	>
		<CRow class="mb-5">
			<CCol md="12">
				<h4>Additional fee</h4>
			</CCol>
			<CCol md="4">
				<div class="form-group mt-3">
					<BaseInputNumber
						id="shipping-fee"
						v-model="$v.localFee.$model"
						:decimal-scale="2"
						:is-valid="!$v.localFee.$error"
						:invalid-feedback="feeErrors"
						label="Additional fee"
						prepend-text="฿"
						text-align="right"
						placeholder="0.00"
					/>
				</div>
			</CCol>
			<CCol md="12">
				<hr class="mb-4">
			</CCol>
			<CCol md="12">
				<div>
					<div class="d-block mb-4">
						<label class="label">
							SKUs
						</label>
						<CButton
							type="button"
							class="btn btn-add float-right"
							color="secondary"
							data-test-id="button-add"
							@click="$refs['modal-sku'].open()"
						>
							{{ `${localSkuList.length ? 'Edit' : 'Add'} SKU` }}
						</CButton>
					</div>
					<div v-if="!localSkuList.length" class="mb-3">
						<BaseNoItemBanner
							:class="{ 'is-invalid': $v.localSkuList.$error }"
							text="There are no SKU for this additional fee"
						/>
						<div v-if="$v.localSkuList.$error" class="invalid-feedback">
							{{ $t('global.error.required') }}
						</div>
					</div>
					<ul v-else class="list-item show-border">
						<p class="show-text">
							{{ localSkuList.join('; ') }}
						</p>
					</ul>

					<ModalSkuList
						ref="modal-sku"
						title="Select SKUs"
						:sku-list="localSkuList"
						@onConfirm="handleSKUChange"
					/>
				</div>
			</CCol>
		</CRow>

		<BaseActionPanelStickyFooter
			:disabled-confirm="isSubmitting"
			:is-edit="isEdit"
			:remove-text="isEdit ? 'Remove rule' : null"
			content-class="main-wrapper"
			@onConfirm="handleSubmit"
			@onCancel="$router.push({ name: 'ShippingSetting'})"
			@onRemove="isEdit ? $refs['modal-remove'].open() : null"
		/>

		<BaseModalConfirmDelete
			ref="modal-remove"
			title="Delete this SKUs additional fee rule?"
			description="Deleting rule will affect upcoming orders immediately."
			@onSuccess="handleRemoveRule"
		/>
	</form>
</template>

<script>
import { required, maxValue } from 'vuelidate/lib/validators';
import ModalSkuList from '@/components/ModalSkuList.vue';
import { MAXIMUM_FEE } from '../enums/shippings';

export default {
	name: 'FormProductAdditionalFee',
	components: {
		ModalSkuList,
	},
	validations: {
		localFee: {
			required,
			maxValue: maxValue(MAXIMUM_FEE),
		},
		localSkuList: {
			required,
		},
	},
	props: {
		fee: {
			type: [String, Number],
			default: '',
		},
		skuList: {
			type: Array,
			default: () => [],
		},
		handleFunc: {
			type: Function,
			default: () => {},
		},
		isSubmitting: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			localFee: null,
			localSkuList: [],
		};
	},
	computed: {
		feeErrors() {
			if (!this.$v.localFee.required) {
				return this.$t('global.error.required');
			}

			if (!this.$v.localFee.maxValue) {
				return 'Please input the maximum number 99,999,999.99';
			}

			return null;
		},

		isEdit() {
			return this.$listeners.onRemoveRule != null;
		},
	},
	mounted() {
		this.localFee = this.fee;
		this.localSkuList = this.skuList;
	},
	methods: {
		handleSKUChange(skus) {
			this.localSkuList = skus;
		},
		handleRemoveSKU(removeProduct) {
			this.localSkuList = this.localSkuList.filter((product) => product.sku !== removeProduct.sku);
		},
		handleRemoveRule() {
			this.$emit('onRemoveRule');
		},
		async handleSubmit() {
			this.$v.$touch();

			if (this.$v.$invalid) {
				return;
			}
			this.$emit('onSubmit', this.localFee, this.localSkuList);
		},
	},
};
</script>

<style lang="scss" scoped>
	.is-invalid {
		border: 1px solid $color-alert;
		border-radius: rem(4);
	}

	.list-item {
		padding: rem(20) rem(16) 0;
		max-height: rem(320);
		overflow: auto;
	}

	.show-text {
		font-size: rem($font-size-caption);
		font-weight: $font-weight-semi-bold;
		color: $color-black-45;
	}

	.show-border {
		border: solid 1px $color-gray-400;
		border-radius: rem(4);
	}
</style>
